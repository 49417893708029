import * as OauthCodeFlowService from './o-auth'

const oauthCodeFlow = OauthCodeFlowService

export const USER_ROLES = {
  ADA_MANAGER: 'role:ADAManager',
  CASE_APPROVER: 'role:CaseApprover',
}

export const SUB_CONTRACTOR = {
  BRIOTIX: 'role:Briotix',
  EASTER_SEALS: 'role:EasterSeals',
}

export const MOBILITY_DIRECT_APPLICANT = {
  MD_APPLICANT: 'role:MobilityDirectApplicant',
}

export const logout = () => {
  sessionStorage.clear()
  oauthCodeFlow.oAuthLogout()
}

export const isAuthenticated = async () => {
  let user = await oauthCodeFlow.getUser()
  oauthCodeFlow.removePostSigninRedirectUrlPath()
  if (user) {
    if (user.expired) {
      // eslint-disable-next-line no-useless-catch
      try {
        user = await oauthCodeFlow.renewToken()
      } catch (error: any) {
        throw error
      }
    }
  } else if (oauthCodeFlow.isIdpRedirect()) {
    try {
      user = await oauthCodeFlow.signinRedirectCallback()
    } catch (error: any) {
      return false
    }
  }

  return !!(user && !user.expired)
  // &&
  // this.checkUserRole()
}

export const getUserRole = async () => {
  const user = await oauthCodeFlow.getUser()

  if (!user) {
    return []
  }

  const userPermissions = user.profile.permission

  return userPermissions.filter((p: string) => p.startsWith('role:'))
}

export const isADAManager = async () => {
  const userRoles = await getUserRole()

  return userRoles.includes(USER_ROLES.ADA_MANAGER)
}

export const getLinkToken = () => {
  const user = oauthCodeFlow.userAvailable()

  return user ? user.access_token : null
}

export const getLinkTokenResult = () => {
  const token = getLinkToken()
  return token ? decodeToken(token) : null
}

export const urlBase64Decode = (str: string) => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0: {
      break
    }
    case 2: {
      output += '=='
      break
    }
    case 3: {
      output += '='
      break
    }
    default: {
      try {
        throw new Error('Illegal base64url string!')
      } catch (e: any) {
        alert(e)
      }
    }
  }

  return decodeURIComponent(window.atob(output))
}

export const decodeToken = (token: string) => {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts')
  }
  const decoded = urlBase64Decode(parts[1])
  if (!decoded) {
    throw new Error('Cannot decode the token')
  }

  return JSON.parse(decoded)
}

export const signIn = () => oauthCodeFlow.signinRedirect()

export const isCaseApprover = async () => {
  const userRoles = await getUserRole()
  return userRoles.includes(USER_ROLES.CASE_APPROVER)
}

export const isSubContractor = async () => {
  const userRoles = await getUserRole()
  if (userRoles.includes(SUB_CONTRACTOR.BRIOTIX) || userRoles.includes(SUB_CONTRACTOR.EASTER_SEALS)) {
    return true
  }
  return false
}

export const isMdApplicant = async () => {
  const userRoles = await getUserRole()
  return userRoles.includes(MOBILITY_DIRECT_APPLICANT.MD_APPLICANT)
}
